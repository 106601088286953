export default {
  title: {
    id: 'diagnosis_customer_issue_invoice_request_failed_title',
    defaultMessage: 'Something went wrong',
  },
  content: {
    id: 'diagnosis_customer_issue_invoice_request_failed_content',
    defaultMessage: 'Your request didn’t go through. Please try again.',
  },
  closeAltText: {
    id: 'toast_close_alternative_text',
    defaultMessage: 'Close',
  },
}
