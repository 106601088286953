export default {
  yourNumber: {
    id: 'diagnosis_customer_issue_imei_modal_your_number',
    defaultMessage: 'Your IMEI number',
    tags: ['Diagnosis', 'imei'],
  },
  findOnKeypad: {
    id: 'diagnosis_customer_issue_imei_modal_find_on_keypad',
    defaultMessage:
      'You can also check it on your device by typing {imei, html} on the keypad in your phone app.',
    tags: ['Diagnosis', 'imei'],
  },
  stillNeedHelpLink: {
    id: 'diagnosis_customer_issue_imei_modal_need_help_link',
    defaultMessage: 'Still need help?',
    tags: ['Diagnosis', 'imei'],
  },
  copyButton: {
    id: 'diagnosis_customer_issue_imei_modal_button_copy',
    defaultMessage: 'Copy your IMEI number',
    tags: ['Diagnosis', 'imei'],
  },
  copySuccessTitle: {
    id: 'diagnosis_customer_issue_imei_modal_copy_success_title',
    defaultMessage: 'Copied!',
    tags: ['Diagnosis', 'imei'],
  },
  closeAltText: {
    id: 'diagnosis_customer_issue_imei_modal_close_alt_text',
    defaultMessage: 'Close',
    tags: ['Diagnosis', 'imei'],
  },
}
