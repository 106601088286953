import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type { MachineContext } from '../Diagnosis.machine.types'

export const getAllSelectedIssuesWithAction = (
  pageData: MachineContext['pageData'],
) => {
  return pageData
    .map((item) =>
      item.selectedIssues?.filter(
        (selectedIssue) => !isEmpty(selectedIssue.action),
      ),
    )
    .flat()
}

export const getAllSelectedIssuesWithFieldAddOnNavigate = (
  pageData: MachineContext['pageData'],
) => {
  return pageData
    .map((item) =>
      item.selectedIssues?.filter(
        (selectedIssue) =>
          selectedIssue.addOnNavigate === true && isEmpty(selectedIssue.action),
      ),
    )
    .flat()
}

export const getAllSelectedCustomerIssuesWithAction = (
  pageData: MachineContext['pageData'],
) => {
  const selectedIssuesWithAction: string[] = []
  const selectedIssuesTagsWithAction: string[] = []

  const allSelectedIssues = [
    ...getAllSelectedIssuesWithFieldAddOnNavigate(pageData),
    ...getAllSelectedIssuesWithAction(pageData),
  ]

  allSelectedIssues.forEach((selectedCustomerIssue) => {
    if (selectedCustomerIssue?.customerIssue) {
      selectedIssuesWithAction.push(selectedCustomerIssue.customerIssue)
    }

    if (selectedCustomerIssue?.tag) {
      if (!selectedIssuesTagsWithAction.includes(selectedCustomerIssue.tag))
        selectedIssuesTagsWithAction.push(selectedCustomerIssue.tag as string)
    }
  })

  return {
    selectedIssues: selectedIssuesWithAction,
    tags: selectedIssuesTagsWithAction,
  }
}
