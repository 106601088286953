<template>
  <section>
    <RevList
      class="bg-action-default-low shadow-short rounded-lg mt-12 overflow-hidden"
      :has-external-borders="false"
      interactive
    >
      <RevListItemInteractive
        v-for="(sibling, index) in siblings"
        :key="index"
        class="first:!border-t-0"
        :data-qa="sibling.customerIssue"
        @click="onClick(sibling)"
      >
        <template #prefix>
          <component :is="getIcon(sibling.icon)" class="shrink-0" />
        </template>

        <template #label>{{ sibling.label }}</template>

        <template #suffix>
          <IconChevronRight />
        </template>
      </RevListItemInteractive>
    </RevList>
  </section>
</template>

<script lang="ts" setup>
import type { IssueSibling } from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/issueListResponse'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import { IconInfo } from '@ds/icons/IconInfo'

import { getCustomerIssueClickedTrackingPayload } from '~/scopes/customer-care/diagnosis/machine/selectors/getCustomerIssueClickedTrackingPayload'

import { ISSUES_LIST_ICON } from './IssuesList.config'
import type { IssuesListProps } from './IssuesList.types'

const props = defineProps<IssuesListProps>()

const emit = defineEmits<{
  continue: [value: IssueSibling]
}>()

const { trackClick } = useTracking()

const logger = useLogger()

function getIcon(iconName: string | null) {
  if (iconName && typeof ISSUES_LIST_ICON[iconName] !== 'undefined') {
    return ISSUES_LIST_ICON[iconName]
  }

  logger.error(`[DIAGNOSIS] Unable to find iconName: ${iconName}`, {
    owners: ['bot-squad-sales-customer-care-front'],
  })

  return IconInfo
}

const onClick = (sibling: IssueSibling) => {
  emit('continue', sibling)
  trackClick(getCustomerIssueClickedTrackingPayload(sibling, props.currentStep))
}
</script>
