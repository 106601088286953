import type { IssueSibling } from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/issueListResponse'

import { formatCustomerIssue } from '@/scopes/customer-care/common/utils/tracking'

import type { MachineContext } from '../Diagnosis.machine.types'

interface getAvailableCustomerIssuesTrackingPayloadParams {
  issueList: IssueSibling[]
  orderlineId: NonNullable<MachineContext['orderlineId']>
  stepNumber: number
}

export const getAvailableCustomerIssuesTrackingPayload = ({
  issueList,
  orderlineId,
  stepNumber,
}: getAvailableCustomerIssuesTrackingPayloadParams) => {
  const formattedIssues = formatCustomerIssue(issueList)

  return {
    customerIssuesAvailable: formattedIssues,
    orderlineId,
    screenType: `diagno_step${stepNumber}`,
    zone: 'diagnosis',
  }
}
