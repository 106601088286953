export default {
  title: {
    id: 'diagnosis_customer_issue_invoice_request_success_modal_title',
    defaultMessage: 'Request sent',
  },
  content: {
    id: 'diagnosis_customer_issue_invoice_request_success_modal_content',
    defaultMessage:
      'Your invoice should be available within the next few days. Just go to “My orders” and click on “Invoice”.',
  },
  cta: {
    id: 'diagnosis_customer_issue_orders_cta',
    defaultMessage: 'Go back to My Orders',
  },
}
