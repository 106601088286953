<template>
  <RevToast
    :close-alternative-text="i18n(translations.closeAltText)"
    :duration="10000"
    icon-src="/img/toast/storm.svg"
    name="requestFailed"
    :opened="true"
    :title="i18n(translations.title)"
    variant="error"
    @close="emit('closed')"
  >
    {{ i18n(translations.content) }}
  </RevToast>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevToast } from '@ds/components/Toast'

import translations from './RequestFailedToast.translations'

const emit = defineEmits(['closed'])

const i18n = useI18n()
</script>
