import type { IssueSibling } from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/issueListResponse'

export const getCustomerIssueClickedTrackingPayload = (
  { tag, customerIssue }: IssueSibling,
  step: number,
) => {
  return {
    name: 'customer_issue_clicked',
    screen_type: `diagno_step${step}`,
    zone: 'diagnosis',
    customer_issue: tag ? `${tag}-${customerIssue}` : customerIssue,
  }
}
