<template>
  <MinimalWithSideBarLayout
    :progress-bar-current-value
    @back="emit('back')"
    @cancel="emit('cancel')"
    @scroll-child-element="handleScrollInElement"
  >
    <template #sidebar>
      <OrderlineRecapBlock :order-id :product-image :product-title />
    </template>

    <template #content>
      <div v-if="!isMultiSelectIssues">
        <h2 class="heading-2">{{ issuesListHeading }}</h2>

        <IssuesList
          class="mb-32 mt-24 md:mt-12"
          :current-step
          :siblings="issue.siblings"
          @continue="onContinueWithSingleIssueSelection"
        />
      </div>

      <div v-if="isMultiSelectIssues">
        <h2 class="heading-2">
          {{ i18n(translations.issuesCheckboxesHeading) }}
        </h2>

        <p class="body-1 mt-1">
          {{ i18n(translations.issuesCheckboxesInformativeText) }}
        </p>

        <div class="mt-24 pb-80">
          <IssuesCheckboxes
            v-model="checkedIssues"
            :current-step
            :siblings="issue.siblings"
          />
        </div>
      </div>
    </template>

    <template #footer v-if="isMultiSelectIssues">
      <RevStickyBar
        position="bottom"
        :snap-to-view-port="isStickyBarSnapToViewPort"
      >
        <div class="md:mx-auto md:max-w-640">
          <RevButton
            data-qa="diagnosis-continue-button"
            :disabled="!hasCheckedIssues"
            full-width="always"
            variant="primary"
            @click="onContinueWithMultipleIssueSelection"
          >
            {{ i18n(translations.continue) }}
          </RevButton>
        </div>
      </RevStickyBar>
    </template>
  </MinimalWithSideBarLayout>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import {
  CUSTOMER_ISSUE,
  type IssueSibling,
} from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/issueListResponse'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useDangerouslyComputedBreakpoint } from '@backmarket/utils/composables/useDangerouslyComputedBreakpoint'
import { Breakpoint } from '@backmarket/utils/dom/getCurrentBreakpoint'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevStickyBar } from '@ds/components/StickyBar'

import OrderlineRecapBlock from '@/scopes/customer-care/common/components/OrderlineRecapBlock/OrderlineRecapBlock.vue'
import { useScrollState } from '@/scopes/customer-care/common/composables/useScrollState'
import MinimalWithSideBarLayout from '@/scopes/customer-care/common/layout/MinimalWithSideBarLayout.vue'

import IssuesCheckboxes from '../IssuesCheckboxes/IssuesCheckboxes.vue'
import IssuesList from '../IssuesList/IssuesList.vue'

import translations from './IssueLayout.translations'
import type { IssueLayoutProps } from './IssueLayout.types'

const emit = defineEmits<{
  back: []
  cancel: []
  continue: [value: IssueSibling | IssueSibling[]]
}>()

const props = defineProps<IssueLayoutProps>()

const breakpoint = useDangerouslyComputedBreakpoint()
const i18n = useI18n()
const { handleScrollInElement } = useScrollState()

const checkedIssues = ref<string[]>([])

const isMultiSelectIssues = computed(() => props.issue.isMultiSelect)

const isStickyBarSnapToViewPort = computed(
  () => !(breakpoint.value >= Breakpoint.MD),
)

const hasCheckedIssues = computed(() => !isEmpty(checkedIssues.value))

const issuesListHeading = computed(() => {
  if (isMultiSelectIssues.value) {
    return null
  }

  if (props.issue.customerIssue === CUSTOMER_ISSUE.root) {
    return i18n(translations.rootIssueListHeading)
  }

  return i18n(translations.issueListHeading)
})

function onContinueWithMultipleIssueSelection() {
  const formatIssuesChecked = props.issue.siblings.filter((sibling) =>
    checkedIssues.value.some((checkedIssue) =>
      sibling.customerIssue.includes(checkedIssue),
    ),
  )

  emit(
    'continue',
    formatIssuesChecked.length > 1
      ? formatIssuesChecked
      : formatIssuesChecked[0],
  )
}

function onContinueWithSingleIssueSelection(event: IssueSibling) {
  emit('continue', event)
}
</script>
