import { type ServiceMap, type Typestate, assign, createMachine } from 'xstate'

import type {
  ToastMachineContext,
  ToastMachineEvent,
  ToastMachineStateSchema,
} from './Toast.machine.types'

export const toastMachine = createMachine<
  ToastMachineContext,
  ToastMachineEvent,
  Typestate<ToastMachineContext>,
  ServiceMap,
  ToastMachineStateSchema
>({
  id: 'toast',
  preserveActionOrder: true,
  predictableActionArguments: true,
  initial: 'idle',
  schema: {
    context: {} as ToastMachineContext,
    events: {} as ToastMachineEvent,
  },
  context: {
    activeToast: null,
  },
  states: {
    idle: {
      id: 'idle',
      on: {
        SHOW_TOAST: {
          actions: assign({
            activeToast: (_, { toast }) => toast ?? null,
          }),
          target: 'open',
        },
      },
    },
    open: {
      id: 'open',
      on: {
        CLOSED_TOAST: {
          actions: assign({
            activeToast: null,
          }),
          target: 'idle',
        },
      },
    },
  },
})
