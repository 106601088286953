export default {
  closeAltText: {
    id: 'diagnosis_customer_issue_imei_modal_close_alt_text',
    defaultMessage: 'Close',
    tags: ['Diagnosis', 'imei'],
  },
  title: {
    id: 'diagnosis_customer_issue_imei_info_modal_title',
    defaultMessage: 'How to find your IMEI number',
    tags: ['Diagnosis', 'imei'],
  },
  greeting1: {
    id: 'diagnosis_customer_issue_imei_info_modal_greeting',
    defaultMessage: 'Hi {firstName}!',
    tags: ['Diagnosis', 'imei'],
  },
  greeting2: {
    id: 'diagnosis_customer_issue_imei_info_modal_greeting2',
    defaultMessage: 'Here’s what you can do.',
    tags: ['Diagnosis', 'imei'],
  },
  keypadInstructions1: {
    id: 'diagnosis_customer_issue_imei_info_modal_keypad_instructions1',
    defaultMessage:
      'For both iPhones and Androids, type {imei, html} on your keypad, and your IMEI number will pop up.',
    tags: ['Diagnosis', 'imei'],
  },
  keypadInstructions2: {
    id: 'diagnosis_customer_issue_imei_info_modal_keypad_instructions2',
    defaultMessage:
      'Or, you can go to Settings and type "IMEI" in the search bar.',
    tags: ['Diagnosis', 'imei'],
  },
  needHelp: {
    id: 'diagnosis_customer_issue_imei_info_modal_need_help',
    defaultMessage: 'Don’t have your phone with you or still need help?',
    tags: ['Diagnosis', 'imei'],
  },
  needHelpLink: {
    id: 'diagnosis_customer_issue_imei_info_modal_need_help_link',
    defaultMessage: 'Ask for help',
    tags: ['Diagnosis', 'imei'],
  },
  accordionTitle: {
    id: 'diagnosis_customer_issue_imei_info_modal_accordion_title',
    defaultMessage: 'Geek out over IMEI',
    tags: ['Diagnosis', 'imei'],
  },
  accordionItemWhatIsImeiTitle: {
    id: 'diagnosis_customer_issue_imei_info_modal_accordion_item_what_is_emeid_title',
    defaultMessage: 'What is an IMEI number?',
    tags: ['Diagnosis', 'imei'],
  },
  accordionItemWhatIsImeiText1: {
    id: 'diagnosis_customer_issue_imei_info_modal_accordion_item_what_is_emeid_text1',
    defaultMessage:
      'The IMEI number (International Mobile Equipment Identity) is a unique 15-digit number that identifies a device on a mobile network. Every smartphone has an IMEI number.',
    tags: ['Diagnosis', 'imei'],
  },
  accordionItemWhatIsImeiText2: {
    id: 'diagnosis_customer_issue_imei_info_modal_accordion_item_what_is_emeid_text2',
    defaultMessage:
      'Simply put, this number is like your phone’s fingerprint. Using this number, the service provider authorizes a device to connect to its network.',
    tags: ['Diagnosis', 'imei'],
  },
  accordionItemWhatIsImeiText3: {
    id: 'diagnosis_customer_issue_imei_info_modal_accordion_item_what_is_emeid_text3',
    defaultMessage:
      'Using the IMEI number, service providers, sellers, and manufacturers can track smartphones that may be lost or stolen. The number is also useful if you need to return your device to the seller. In this case, the seller may ask you for the IMEI number to make sure you’re returning the correct device.',
    tags: ['Diagnosis', 'imei'],
  },
  accordionItemOtherWaysTitle: {
    id: 'diagnosis_customer_issue_imei_info_modal_accordion_item_other_ways',
    defaultMessage: 'Other ways to find an IMEI number',
    tags: ['Diagnosis', 'imei'],
  },
  accordionItemOtherWaysText1: {
    id: 'diagnosis_customer_issue_imei_info_modal_accordion_item_other_ways_text1',
    defaultMessage:
      'If the phone is switched off, it’s still possible to find the IMEI on a label attached on the battery. This only works for phones with a removable battery.',
    tags: ['Diagnosis', 'imei'],
  },
  accordionItemOtherWaysText2: {
    id: 'diagnosis_customer_issue_imei_info_modal_accordion_item_other_ways_text2',
    defaultMessage:
      'If the phone is lost or stolen, the IMEI is sometimes located on the phone’s original packaging. This is unfortunately not always the case, especially for refurbished items.',
    tags: ['Diagnosis', 'imei'],
  },
  accordionItemOtherWaysText3: {
    id: 'diagnosis_customer_issue_imei_info_modal_accordion_item_other_ways_text3',
    defaultMessage:
      'As a last resort, the IMEI is sometimes found on the phone’s purchase invoice.',
    tags: ['Diagnosis', 'imei'],
  },
}
