<template>
  <div>
    <RevCard
      class="flex flex-row items-center gap-x-24 px-16 py-8"
      @click="onClickCopy"
    >
      <p
        class="heading-2 grow cursor-pointer overflow-hidden text-ellipsis text-left"
      >
        {{ copyValue }}
      </p>
      <RevButtonIcon
        :alternative-text="copyButtonAltText"
        :icon="copyIcon"
        variant="secondary"
        @click="onClickCopy"
      />
    </RevCard>

    <RevToast
      :close-alternative-text="toastCloseAltText"
      icon-src="/img/toast/rabbit.svg"
      name="toast_copy_clipboard"
      :opened="isToastOpen"
      :title="toastTitle"
      to="#toast-wrapper"
      variant="success"
      @close="onToastClose"
    >
      {{ toastMessage }}
    </RevToast>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { RevButtonIcon } from '@ds/components/ButtonIcon'
import { RevCard } from '@ds/components/Card'
import { RevToast } from '@ds/components/Toast'
import { IconCheckLarge } from '@ds/icons/IconCheckLarge'
import { IconCopy } from '@ds/icons/IconCopy'

import type { CopyToClipboardProps } from './CopyToClipboard.types'
import { copyToClipboard } from './CopyToClipboard.util'

const { copyValue, toastMessage = '' } = defineProps<CopyToClipboardProps>()

const hasCopied = ref<boolean>(false)
const isToastOpen = ref<boolean>(false)

const copyIcon = computed(() => (hasCopied.value ? IconCheckLarge : IconCopy))

async function onClickCopy() {
  try {
    await copyToClipboard(copyValue)
    hasCopied.value = true
    isToastOpen.value = true
  } catch (e) {
    // This can occur if e.g. the user has no permission to copy.
    // Considering this to be very rare occurance, hence no handling here.
  }
}

function onToastClose() {
  isToastOpen.value = false
}
</script>
