import { ISSUE_ACTION_TYPE } from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/common'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type { MachineContext } from '../Diagnosis.machine.types'

import { getCurrentPageData } from './getCurrentPageData'

export const getExternalUrlFromSelectedIssues = (
  pageData: MachineContext['pageData'],
) => {
  const currentPageData = getCurrentPageData(pageData)

  const currentSelectedIssues = currentPageData?.selectedIssues

  if (isEmpty(currentSelectedIssues)) return null

  if (currentSelectedIssues?.length !== 1) return null

  const selectedIssue = currentSelectedIssues[0]

  if (
    [ISSUE_ACTION_TYPE.externalRedirect, ISSUE_ACTION_TYPE.insurerPortal].some(
      (type) => type === selectedIssue?.action?.type,
    )
  )
    return selectedIssue?.action?.target

  return null
}
