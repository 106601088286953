<template>
  <IssueLayout
    v-if="!isMultipleIssues"
    :current-step="machineContext.pageData.length"
    data-qa="diagnosis-issue-layout"
    data-test="diagnosis-issue-layout"
    :issue="issueData"
    :order-id
    :product-image
    :product-title
    :progress-bar-current-value="machineContext.progressBarCurrentValue"
    @back="goBack"
    @cancel="emit('cancel')"
    @continue="onContinue"
  />

  <IssuesLayout
    v-if="isMultipleIssues"
    :current-step="machineContext.pageData.length"
    data-qa="diagnosis-issues-layout"
    data-test="diagnosis-issues-layout"
    :issues="issuesData"
    :order-id
    :product-image
    :product-title
    :progress-bar-current-value="machineContext.progressBarCurrentValue"
    @back="goBack"
    @cancel="emit('cancel')"
    @continue="onContinue"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { IssueSibling } from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/issueListResponse'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { getCurrentPageData } from '../../machine/selectors/getCurrentPageData'

import type { PickIssuesScreenProps } from './PickIssuesScreen.types'
import IssueLayout from './components/IssueLayout/IssueLayout.vue'
import IssuesLayout from './components/IssuesLayout/IssuesLayout.vue'

const emit = defineEmits(['cancel'])

const props = defineProps<PickIssuesScreenProps>()

const stateChartIssues = computed(
  () => getCurrentPageData(props.machineContext.pageData)?.data,
)

const isMultipleIssues = computed(() => stateChartIssues.value.length > 1)

const issueData = computed(() => stateChartIssues.value[0])

const issuesData = computed(() =>
  stateChartIssues.value.filter((issue) => !isEmpty(issue.siblings)),
)

function goBack() {
  props.machineSend('GO_BACK')
}

function onContinue(event: IssueSibling | IssueSibling[]) {
  props.machineSend({
    type: 'SELECT_ISSUES',
    selectedIssues: event,
  })
}
</script>
