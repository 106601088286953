export default {
  title: {
    id: 'diagnosis_customer_issue_invoice_request_pending_modal_title',
    defaultMessage: 'Invoice request received',
  },
  content1: {
    id: 'diagnosis_customer_issue_invoice_request_pending_modal_content1',
    defaultMessage:
      'Your invoice should be available within the next few days. Find it by going to “My orders” and clicking on “Invoice”.',
  },
  content2: {
    id: 'diagnosis_customer_issue_invoice_request_pending_modal_content2',
    defaultMessage: 'Still don’t see it? Please send another request.',
  },
  cta1: {
    id: 'diagnosis_customer_issue_invoice_request_pending_modal_send_another_request',
    defaultMessage: 'Send another request',
  },
  cta2: {
    id: 'diagnosis_customer_issue_orders_cta',
    defaultMessage: 'Go back to My Orders',
  },
}
