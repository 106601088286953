export default {
  title: {
    id: 'diagnosis_customer_issue_invoice_request_orderline_canceled_modal_title',
    defaultMessage: 'Invoice not available',
  },
  firstParagraph: {
    id: 'diagnosis_customer_issue_invoice_request_orderline_canceled_modal_content_first_paragraph',
    defaultMessage:
      'We can’t send you an invoice because your order was canceled. Don’t worry, your account was not charged.',
  },
  secondParagraph: {
    id: 'diagnosis_customer_issue_invoice_request_orderline_canceled_modal_content_second_paragraph',
    defaultMessage:
      'Any pending payment you may see in your account will be removed within 5 business days.',
  },
  cta: {
    id: 'diagnosis_customer_issue_orders_cta',
    defaultMessage: 'Go back to My Orders',
  },
}
