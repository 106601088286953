import type { IssueSibling } from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/issueListResponse'
import type { State } from 'xstate'

import type { MachineContext } from './Diagnosis.machine.types'

/**
 * Workaround for https://github.com/statelyai/xstate/issues/890
 * Remove this when we start using XState 5.x.
 *
 * Enables transient state guards to get the last triggered event.
 *
 * From: https://github.com/statelyai/xstate/issues/890#issuecomment-1026903228
 *
 */

export function lastEvent(
  state: State<MachineContext, { type: ''; skipModal?: boolean }>,
):
  | undefined
  | { type: ''; skipModal?: boolean }
  | {
      type: 'SELECT_ISSUES'
      selectedIssues: IssueSibling | IssueSibling[] | null
      skipModal?: boolean
    } {
  if (!state) {
    throw Error('[DIAGNOSIS] state not set inside lastEvent')
  }

  if (state.event.type) {
    return state.event
  }

  // since state.event is *also* not set, we need to use state.history
  return state.history ? lastEvent(state.history) : undefined
}
