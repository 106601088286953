<template>
  <RevDialog
    :name="DIAGNOSIS_MODALS_NAMES.invoiceRequestModalCanceled"
    :title="i18n(translations.title)"
  >
    <template #body>
      <p class="body-1">
        {{ i18n(translations.firstParagraph) }}
      </p>

      <p class="body-1 mb-12 mt-12">
        {{ i18n(translations.secondParagraph) }}
      </p>
    </template>

    <template #footer>
      <RevButton
        class="mb-12"
        data-qa="goto-my-order"
        full-width="always"
        :to="myOrdersLink"
        variant="primary"
      >
        {{ i18n(translations.cta) }}
      </RevButton>
    </template>
  </RevDialog>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'

import { DASHBOARD } from '@/scopes/dashboard/routes'

import { DIAGNOSIS_MODALS_NAMES } from '../name'

import translations from './InvoiceRequestCanceledModal.translations'

const i18n = useI18n()
const injectLocale = useRouteLocationWithLocale()

const myOrdersLink = toNuxtLink(
  injectLocale({
    type: 'internal',
    name: DASHBOARD.MY_ORDERS.SELF,
  }),
)
</script>
