import { postOrderAskBill } from '@backmarket/http-api/src/api-specs-order-management/orders/order'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

import type { MachineContext } from '../Diagnosis.machine.types'

export async function doInvoiceRequest({ orderId }: MachineContext) {
  return await $httpFetch(postOrderAskBill, {
    pathParams: {
      id: orderId,
    },
    body: { order_id: orderId as number },
  })
}
