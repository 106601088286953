import { defineAsyncComponent } from 'vue'

export const ISSUES_LIST_ICON: Record<string, unknown> = {
  IconArchive: defineAsyncComponent(() =>
    import('@ds/icons/IconArchive').then((m) => m.IconArchive),
  ),
  IconBill: defineAsyncComponent(() =>
    import('@ds/icons/IconBill').then((m) => m.IconBill),
  ),
  IconClock: defineAsyncComponent(() =>
    import('@ds/icons/IconClock').then((m) => m.IconClock),
  ),
  IconContact: defineAsyncComponent(() =>
    import('@ds/icons/IconContact').then((m) => m.IconContact),
  ),
  IconChargingWire: defineAsyncComponent(() =>
    import('@ds/icons/IconChargingWire').then((m) => m.IconChargingWire),
  ),
  IconCrossInCircle: defineAsyncComponent(() =>
    import('@ds/icons/IconCrossInCircle').then((m) => m.IconCrossInCircle),
  ),
  IconCupcake: defineAsyncComponent(() =>
    import('@ds/icons/IconCupcake').then((m) => m.IconCupcake),
  ),
  IconExport: defineAsyncComponent(() =>
    import('@ds/icons/IconExport').then((m) => m.IconExport),
  ),
  IconGear: defineAsyncComponent(() =>
    import('@ds/icons/IconGear').then((m) => m.IconGear),
  ),
  IconHeartBroken: defineAsyncComponent(() =>
    import('@ds/icons/IconHeartBroken').then((m) => m.IconHeartBroken),
  ),
  IconHeartExploded: defineAsyncComponent(() =>
    import('@ds/icons/IconHeartExploded').then((m) => m.IconHeartExploded),
  ),
  IconLocationPinOutlined: defineAsyncComponent(() =>
    import('@ds/icons/IconLocationPinOutlined').then(
      (m) => m.IconLocationPinOutlined,
    ),
  ),
  IconHome: defineAsyncComponent(() =>
    import('@ds/icons/IconHome').then((m) => m.IconHome),
  ),
  IconQuality: defineAsyncComponent(() =>
    import('@ds/icons/IconQuality').then((m) => m.IconQuality),
  ),
  IconMoney: defineAsyncComponent(() =>
    import('@ds/icons/IconMoney').then((m) => m.IconMoney),
  ),
  IconPackage: defineAsyncComponent(() =>
    import('@ds/icons/IconPackage').then((m) => m.IconPackage),
  ),
  IconPeople: defineAsyncComponent(() =>
    import('@ds/icons/IconPeople').then((m) => m.IconPeople),
  ),
  IconPromo: defineAsyncComponent(() =>
    import('@ds/icons/IconPromo').then((m) => m.IconPromo),
  ),
  IconSearch: defineAsyncComponent(() =>
    import('@ds/icons/IconSearch').then((m) => m.IconSearch),
  ),
  IconSim: defineAsyncComponent(() =>
    import('@ds/icons/IconSim').then((m) => m.IconSim),
  ),
  IconSiren: defineAsyncComponent(() =>
    import('@ds/icons/IconSiren').then((m) => m.IconSiren),
  ),
  IconSmartphoneBroken: defineAsyncComponent(() =>
    import('@ds/icons/IconSmartphoneBroken').then(
      (m) => m.IconSmartphoneBroken,
    ),
  ),
  IconSparkles: defineAsyncComponent(() =>
    import('@ds/icons/IconSparkles').then((m) => m.IconSparkles),
  ),
  IconStarHalf: defineAsyncComponent(() =>
    import('@ds/icons/IconStarHalf').then((m) => m.IconStarHalf),
  ),
  IconTruck: defineAsyncComponent(() =>
    import('@ds/icons/IconTruck').then((m) => m.IconTruck),
  ),
  IconWarning: defineAsyncComponent(() =>
    import('@ds/icons/IconWarning').then((m) => m.IconWarning),
  ),
  IconWaterdrop: defineAsyncComponent(() =>
    import('@ds/icons/IconWaterdrop').then((m) => m.IconWaterdrop),
  ),
}
