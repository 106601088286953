export default {
  title: {
    id: 'diagnosis_insurance_modal_title',
    defaultMessage: 'The insurer will take over from here',
  },
  content: {
    id: 'diagnosis_insurance_modal_text',
    defaultMessage:
      "Important! If your device has a technical failure (e.g not damaged externally due to an accident) this type of problem falls under your warranty, which means only the seller can repair it. If this is the case, close this window and choose ' I have a problem with the product/ accessories' instead. If you're here to make a claim with our insurance partner, you will be redirected to their website to report it there . In order for the insurer to find your contract, please use your backmarket email address when you register.",
  },
  cta: {
    id: 'diagnosis_insurance_modal_cta',
    defaultMessage: 'Go to the insurer’s website',
  },
}
