export default {
  continue: {
    id: 'dashboard_diagnosis_continue',
    defaultMessage: 'Continue',
  },
  issuesCheckboxesHeading: {
    id: 'diagnosis_issues_checkboxes_subtitle',
    defaultMessage: 'What do you think is causing the issue ?',
  },
  issuesCheckboxesInformativeText: {
    id: 'diagnosis_issues_checkboxes_heading',
    defaultMessage: 'Select all that apply.',
  },
  rootIssueListHeading: {
    id: 'diagnosis_root_issues_list_subtitle',
    defaultMessage: 'Select your issue',
  },
  issueListHeading: {
    id: 'diagnosis_issues_list_subtitle',
    defaultMessage: 'Tell us more',
  },
}
