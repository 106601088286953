export default {
  title: {
    id: 'diagnosis_customer_issue_invoice_request_orderline_not_shipped_modal_title',
    defaultMessage: 'You can request an invoice once your order has shipped',
  },
  content1: {
    id: 'diagnosis_customer_issue_invoice_request_orderline_not_shipped_modal_content_first_paragraph',
    defaultMessage:
      "Looks like your order hasn't shipped yet. You can request an invoice after shipment, or check your package when it arrives — some sellers include a printed invoice inside the box or via email.",
  },
  content2: {
    id: 'diagnosis_customer_issue_invoice_request_orderline_not_shipped_modal_content_second_paragraph',
    defaultMessage:
      "Also, if your order is canceled or refunded, they won't be able to give you an invoice.",
  },
  cta: {
    id: 'diagnosis_customer_issue_orders_cta',
    defaultMessage: 'Go back to My Orders',
  },
}
