import { closeModal, openModal } from '@ds/components/ModalBase'
import type { EventObject, Receiver, Sender } from 'xstate'

import { MODAL_EVENTS_TYPE } from './Modal.constants'
import type { ModalEvent } from './Modal.types'

/**
 * Actor which can be used to open or close modals.
 *
 * @example
 * send({type: ModalEvents.OPEN, modalName: 'foobar' }, {to: 'modal'})
 * send({type: ModalEvents.CLOSE }, {to: 'modal'})
 */
export function modalCallbackActor(
  _callback: Sender<EventObject>,
  receive: Receiver<ModalEvent>,
) {
  receive((event) => {
    if (event.type === MODAL_EVENTS_TYPE.OPEN) {
      if (event.modalName) {
        openModal(event.modalName)
      }
    } else if (event.type === MODAL_EVENTS_TYPE.CLOSE) {
      closeModal()
    }
  })
}
