<template>
  <RevDrawer
    large
    :name="DIAGNOSIS_MODALS_NAMES.imeiUnavailableModal"
    :title="i18n(translations.title)"
    variant="panel"
  >
    <template #body>
      <h2 class="heading-3">
        <FormattedMessage
          class="block"
          :definition="translations.greeting1"
          :values="{ firstName: firstName }"
        />
        {{ i18n(translations.greeting2) }}
      </h2>
      <RevCard class="mt-8 px-12 py-16">
        <FormattedMessage
          class="body-1"
          :definition="translations.keypadInstructions1"
          tag="p"
        >
          <template #imei>
            <span class="body-2-bold">*#06#</span>
          </template>
        </FormattedMessage>

        <RevIllustration
          alt=""
          class="mx-auto mt-16 block max-h-[400px] object-contain"
          :height="563"
          src="/img/customer-care/diagnosis/imei-instructions.png"
          :width="825"
        />

        <p class="body-2 mt-16">
          {{ i18n(translations.keypadInstructions2) }}
        </p>
      </RevCard>

      <p class="body-1 mt-8">
        {{ i18n(translations.needHelp) }}
        <RevLink @click="emit('still-need-help')">{{
          i18n(translations.needHelpLink)
        }}</RevLink>
      </p>

      <h2 class="heading-2 mt-32">
        {{ i18n(translations.accordionTitle) }}
      </h2>

      <RevCard class="mt-8 overflow-hidden">
        <RevAccordionList :has-external-borders="false">
          <RevAccordionItem>
            <template #title>
              {{ i18n(translations.accordionItemWhatIsImeiTitle) }}
            </template>
            <template #body>
              <p class="body-1">
                {{ i18n(translations.accordionItemWhatIsImeiText1) }}
              </p>
              <p class="body-1 pt-[2ch]">
                {{ i18n(translations.accordionItemWhatIsImeiText2) }}
              </p>
              <p class="body-1 pt-[2ch]">
                {{ i18n(translations.accordionItemWhatIsImeiText3) }}
              </p>
            </template>
          </RevAccordionItem>
          <RevAccordionItem>
            <template #title>
              {{ i18n(translations.accordionItemOtherWaysTitle) }}
            </template>
            <template #body>
              <div class="flex flex-col">
                <div class="flex">
                  <IconBattery class="mr-16" />
                  <p class="body-1 flex-1">
                    {{ i18n(translations.accordionItemOtherWaysText1) }}
                  </p>
                </div>
                <div class="mt-16 flex">
                  <IconArchive class="mr-16" />
                  <p class="body-1 flex-1">
                    {{ i18n(translations.accordionItemOtherWaysText2) }}
                  </p>
                </div>
                <div class="mt-16 flex">
                  <IconBill class="mr-16" />
                  <p class="body-1 flex-1">
                    {{ i18n(translations.accordionItemOtherWaysText3) }}
                  </p>
                </div>
              </div>
            </template>
          </RevAccordionItem>
        </RevAccordionList>
      </RevCard>
    </template>
  </RevDrawer>
</template>

<script lang="ts" setup>
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevAccordionList } from '@ds/components/AccordionList'
import { RevCard } from '@ds/components/Card'
import { RevDrawer } from '@ds/components/Drawer'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { IconArchive } from '@ds/icons/IconArchive'
import { IconBattery } from '@ds/icons/IconBattery'
import { IconBill } from '@ds/icons/IconBill'

import { DIAGNOSIS_MODALS_NAMES } from '../name'

import translations from './ImeiUnavailableModal.translations'
import type { ImeiUnavailableModalProps } from './ImeiUnavailableModal.types'

defineProps<ImeiUnavailableModalProps>()

const emit = defineEmits(['still-need-help'])

const i18n = useI18n()
</script>
